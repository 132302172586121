<template>
    <div>
     <Banner/>
     <Donation/>
     <Show/>
     <Dmethods/>
     <Ourimpact/>
     <Partners/>
     <Bottom/>



    </div>
</template>
<script>
import Banner from "./banner";
import Donation from "./donationFormROP";
import Show from "./showYourSupport";
import Dmethods from "./donationMethods";
import Ourimpact from "./ourImpact";
import Partners from "./partners";
import Bottom from "./bottom";



export default {
    components:{
        Banner,
        Donation,
        Show,
        Dmethods,
        Ourimpact,
        Partners,
        Bottom
    }
    
}   
</script>